import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { navigate, graphql } from "gatsby";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import classNames from "classnames";

// import BoxFilter from "../components/box-filter";
import BoxPost from "../components/box-post";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const ALL_CATEGORY = "ALL";

const Blog = ({ data: { posts }, location }) => {
  const [page, setPage] = useState(1);
  const [currentCategory, setCurrentCategory] = useState(ALL_CATEGORY);

  // const setQueryCategory = useCallback(
  //   (category) => {
  //     const params = new URLSearchParams(location.search);
  //     if (category === ALL_CATEGORY) {
  //       params.delete("category");
  //     } else {
  //       params.set("category", category);
  //     }
  //     const qs = params.toString();
  //     const to = (qs ? `?${qs}` : "") + location.hash;
  //     navigate(to);
  //     setCurrentCategory(category);
  //   },
  //   [location]
  // );

  useEffect(() => {
    setPage(1);
  }, [currentCategory]);

  const allPosts = posts.nodes;

  const filters = useMemo(() => {
    const allFilters = allPosts.reduce((res, post) => {
      res.add(post.category);
      return res;
    }, new Set());
    return [ALL_CATEGORY].concat([...allFilters].sort((a, b) => (a < b ? -1 : 1)));
  }, [allPosts]);

  useEffect(() => {
    const queryCategory = new URLSearchParams(location.search).get("category");
    if (queryCategory !== ALL_CATEGORY && filters.includes(queryCategory)) {
      setCurrentCategory(queryCategory);
    } else if (!queryCategory) {
      setCurrentCategory(ALL_CATEGORY);
    }
  }, [filters, location.search]);

  const filteredPosts = useMemo(
    () =>
      currentCategory === ALL_CATEGORY
        ? allPosts
        : allPosts.filter((post) => post.category === currentCategory),
    [currentCategory, allPosts]
  );
  const paginatedPosts = useMemo(() => filteredPosts.slice(0, page * 12), [page, filteredPosts]);
  const showMore = useCallback(() => setPage((current) => current + 1), []);

  return (
    <Layout>
      <Seo
        title={`Il blog di Intranet 80.20: consigli e soluzioni`}
        description={`Rimani sempre aggiornato sulle ultime tendenze e soluzioni per la gestione della tua intranet aziendale. Leggi i nostri consigli e approfondimenti sul blog!`}
      />

      <div className="hero">
        <div className="container-lg position-relative">
          <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
          <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
          <div className="row">
            <div className="col-10 col-md-10 col-lg-8">
              <h1 className="hero__title">
                <strong>Blog</strong>
                <br />
                Qui troverai novità, ispirazioni e soluzioni per la tua intranet e la comunicazione
                interna.
              </h1>
            </div>
          </div>
        </div>
        <div className="hero__text-scroll hero__text-scroll--classic">
          <div className="text-scroll" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase blog">Blog</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase blog">Blog</span>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--grey">
        <div className="container-lg">
          {/* <div className="row">
            <div className="col-12">
              <BoxFilter items={filters} filter={currentCategory} setFilter={setQueryCategory} />
            </div>
          </div> */}

          <div className="row">
            {paginatedPosts.map((item, i) => (
              <div
                key={i}
                className={classNames(
                  { "col-12 col-sm-7": i === 0 },
                  { "col-12 col-sm-5": i === 1 },
                  { "col-12 col-sm-6 col-lg-4": i > 1 }
                )}
              >
                <BoxPost data={item} position={i} />
              </div>
            ))}
          </div>

          {!!paginatedPosts && filteredPosts.length > paginatedPosts.length && (
            <div className="row mt-3 mt-xl-5">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={showMore}
                    className="btn-link btn-link--secondary showmore-click-tracking-ga"
                    data-ga-id={`mostra altri post`}
                  >
                    carica altri
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query {
    posts: allContentfulPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        date(formatString: "DD MMMM YYYY", locale: "it")
        category
        image {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        abstract {
          abstract
        }
        text {
          text
        }
        link
      }
    }
  }
`;
